<template>
    <div style="position: relative; height: 100%; overflow: auto;">
        <h1 style="margin-left: 64px; margin-top: 32px; margin-bottom:20px;">{{ $t('DefaultSettings.title') }}</h1>

        <div v-if="(default_project_options != undefined)" style="width: 40%;padding: 1%;border-radius: 20px;margin: auto; ">


            <div style="display: flex; justify-content: space-between;">
                <div style="width: 45%;">
                    <div class="optionContainer">
                        <h2 style="margin-bottom: 5%;">{{ $t('ProjectOptions.format.title') }}</h2>
                        <imgTextRow srcImg="/toolbar/horizontal.svg" text="Horizontal (16:9)"
                            :selected="default_project_options.format == 'horizontal'"
                            @click="select_format('horizontal')" />
                        <imgTextRow srcImg="/toolbar/vertical.svg" text="Vertical (9:16)"
                            :selected="default_project_options.format == 'vertical'"
                            @click="select_format('vertical')" />
                        <imgTextRow srcImg="/toolbar/square.svg" :text="$t('ProjectOptions.format.square') + ' (1:1)'"
                            @click="select_format('square')" :selected="default_project_options.format == 'square'" />
                    </div>

                    <div class="optionContainer">
                        <div style="display: flex;justify-content: space-between;margin-bottom: 0%;">
                            <h2>Watermark </h2>
                            <Toggle v-model="watermark_bool" :disabled="!isSubscribed" id="toggle_watermark" />
                        </div>
                        <p v-if="!isSubscribed" style="text-align: center;margin-top:2%;color: var(--color_negative)">
                            {{ $t('ProjectOptions.watermark.notEligible') }}</p>
                        <p v-if="default_project_options.watermark != null" class="subtitlesOptions"
                            style="margin-top: 0%;">
                            {{ $t('ProjectOptions.watermark.logoLocation') }}</p>
                        <selectImg v-if="default_project_options.watermark != null"
                            style="margin: auto;margin-bottom: 10%;margin-top: 5%;"
                            v-model="default_project_options.watermark" :data="[
                            { srcImg: '/toolbar/watermark-BL.svg', value: 'BL' },
                            { srcImg: '/toolbar/watermark-BR.svg', value: 'BR' },
                            { srcImg: '/toolbar/watermark-TL.svg', value: 'TL' },
                            { srcImg: '/toolbar/watermark-TR.svg', value: 'TR' }]" @update:modelValue="update" />
                        <h4 class="subtitlesOptions">{{ $t('ProjectOptions.description') }}</h4>
                        <p style="margin: 2% 0%">{{ $t('ProjectOptions.watermark.description') }}
                        </p>
                        <h4 class="subtitlesOptions">{{ $t('ProjectOptions.overview') }}</h4>
                        <video src="https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/toolbar_content/Watermark_video_sample.mp4" autoplay muted></video>
                    </div>

                </div>

                <div style="width: 45%;">

                    <div class="optionContainer" id="editing_options">
                        <h2 class="toolbar_tab_header">{{ $t('ProjectOptions.editing_mode.title') }}</h2>
                        
                        <div class="inputRange" style="margin-top:20px">
                            <p>{{ $t('ProjectOptions.cut_level.title') }} : <a id="cut_level_value_displayed" style="font-weight: 600;">{{ $t('ProjectOptions.cut_level.' + default_project_options.cut_level)
                        }}</a></p>
                            <input id="cut_level" v-model="cut_level_valueInt" type="range" min="0" max="3" step="1" @change="update" />
                        </div>

                        <div style="display: flex;justify-content: space-between;margin-top:20px;" >
                        <p>{{ $t('ProjectOptions.auto_crop.title') }} <img src='../../assets/info.svg' class="tooltip" @mouseover="playTippyVideo('tippyVideo_crop')" v-tippy="{ maxWidth: 500, allowHTML: true, content: tippyContent.projectOptionCrop, placement: 'left'}"/></p>
                        <Toggle v-model="default_project_options.auto_crop"
                            @update:modelValue="update"
                            id="toggle_framing" />

                        </div>
                        <div style="display: flex;justify-content: space-between;margin-top:20px;">
                            <p>Zoom <img src='../../assets/info.svg' class="tooltip" @mouseover="playTippyVideo('tippyVideo_zoom')" v-tippy="{ maxWidth: 500, allowHTML: true, content: tippyContent.projectOptionZoom, placement: 'left'}"/> </p>
                        <Toggle v-model="default_project_options.zoom"
                            @update:modelValue="update"
                            id="toggle_zoom" />
                        </div>

                        <div style="display: flex;justify-content: space-between;margin-top:20px;">
                            <p>{{ $t('ProjectOptions.audio_enhancement') }} </p>
                            <Toggle v-model="default_project_options.audio_enhanced" id="toggle_audio" @update:modelValue="update" />
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>

import axios from "axios";
import Vuex from "vuex";

//Components
import imgTextRow from "../../components/Object/Toolbar/components/imgTextRow.vue"
import selectImg from "../../components/Object/Toolbar/components/selectImg.vue";
import SelectDropdown from "../../components/Object/SelectDropdown/SelectDropdown.vue";


const sleep = ms => new Promise(r => setTimeout(r, ms));

export default {
    data() {
        return {
            default_project_options: undefined,
            cutLevelValues: ["none", "low", "medium", "high"],
            editingModesTemplate: [
                {
                label: this.$t('ProjectOptions.editing_mode.original'),
                img: "/video_media_blue.png",
                value: "original"
                },
                {
                label: this.$t('ProjectOptions.editing_mode.natural'),
                img: "/toolbar/editing_mode_natural.png",
                value: "natural"
                },
                {
                label: this.$t('ProjectOptions.editing_mode.dynamic'),
                img: "/toolbar/editing_mode_dynamic.png",
                value: "dynamic"
                },
                {
                label: this.$t('ProjectOptions.editing_mode.intense'),
                img: "/toolbar/editing_mode_intense.png",
                value: "intense"
                }
            ],
            tippyContent : {
                projectOptionCrop : "<p style='padding:5px'> <video id='tippyVideo_crop' src='https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/toolbar_content/Autocrop_video_sample.mp4' webkit-playsinline='true' playsinline='true' loop autoplay muted width='380px' ></video></p>",
                projectOptionZoom : "<p style='padding:5px'> <video id='tippyVideo_zoom' src='https://yopbox-bucket-public.s3.eu-west-3.amazonaws.com/toolbar_content/tippy_zoom_3.mp4' webkit-playsinline='true' playsinline='true' loop autoplay muted width='380px' ></video></p>"
            }
        };
    },
    components: { imgTextRow, selectImg, SelectDropdown },
    mounted() {
        this.load_defaultOptions()
    },
    computed: {
        ...Vuex.mapGetters(["getCurrentUsrId", "isSubscribed"]),
        watermark_bool: {
            get() {
                if (this.default_project_options == undefined ||
                    this.default_project_options.watermark == null
                ) {
                    return false
                } else {
                    return true
                }
            },
            set(value) {
                if (!value) {
                    this.default_project_options.watermark = null
                } else {
                    this.default_project_options.watermark = 'BL'
                }
                this.update()
            }
        },
        cut_level_valueInt: {
            get() {
                const index = this.cutLevelValues.findIndex(v => v == this.default_project_options.cut_level)
                if (index != -1) {
                    return index
                } else {
                    return null
                }

            },
            set(newValue) {
                this.default_project_options.cut_level = this.cutLevelValues[newValue]
            }
        }
    },
    watch: {
        getCurrentUsrId() {
            this.load_defaultOptions()
        }
    },
    methods: {
        load_defaultOptions() {
            axios.get("/users/" + this.getCurrentUsrId).then((res) => {
                this.default_project_options = res.data.default_project_options
            });
        },
        update() {
            axios
                .put("/users/" + this.getCurrentUsrId, { default_project_options: this.default_project_options })
                .then(() => {
                    // this.Toaster.success(this.$t('Toaster.success.update'))
                })
                .catch(() => {
                    this.Toaster.error(this.$t('Toaster.error.update'))
                });
        },
        select_format(value) {
            this.default_project_options.format = value
            this.update()
        },
        select_editing_mode(mode) {
            this.default_project_options.editing_mode = mode
            this.update()
        },
        async playTippyVideo(videoId){
            while (document.getElementById(videoId) == null) {
                await sleep(100)
            }
            document.getElementById(videoId).currentTime = 0;
            document.getElementById(videoId).play()
        }
    },
};
</script>

<style src="./style.css">

</style>
  
